import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs'
import { environment } from '../../environments/environment';

const URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class FeeManagementService {

  constructor(
    private http: HttpClient
  ) { }

  getCourseFeeDetails(data: any) {
    return this.http.post(`${URL}/FetchFeeSplitUpAndFeeFullDetails`, data)
      .pipe(
        catchError(this.handleError)
      );
  }
  getPartialPAymentInfo() {
    return this.http.get(`${URL}/partial_payment_status`)
      .pipe(
        catchError(this.handleError)
      );
  }


  paySplitFeeDetails(data: any) {
    // return this.http.post(`${URL}/InsertPayment`, data)
    return this.http.post(`${URL}/InsertPaymentWithPgName`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  tabbyPrescoreCheck(data: any) {
    return this.http.post(`${URL}/Tabby-Prescore-Check`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  tabbyPayment(data: any) {
    // return this.http.post(`${URL}/InsertPayment`, data)
    return this.http.post(`${URL}/InsertPaymentViaTabby`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  fetchSettingsValues(data: any) {
    // return this.http.post(`${URL}/InsertPayment`, data)
    return this.http.post(`${URL}/FetchSettingsValuesBasedonKey`, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllPaymentMethods() {
    return this.http.get(`${URL}/Fetch-All-Payment-Methods`)
      .pipe(
        catchError(this.handleError)
      );
  }
  getTabbySettingsKeys(data) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${URL}/Fetch-Tabby-Settings-Keys`, data, { headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = error.error;
    }
    return throwError(errorMessage);
  }


}
